<template>
  <div>
    <div v-if="!loading_proj">
      <template v-if="projects">
        <div class="lb-mb-20">
          <div class="title text-media h4 lb-mb-2">
            <span class="max-l-b">Projects</span>
          </div>
          <div class="max-l-b text-md">Contact and additional information about you for the client.</div>
        </div>
        <div class="row row-32 card-wrap">
          <div class="col-lg-6" v-for="(item, i) in projects" :key="i">
            <div class="card type-two bg-white">
              <div class="card-header lb-mb-15">
                <div class="card-image">
                  <img v-if="item.image && checkURL(item.image)" :src="item.image" alt="image">
                  <img v-else src="@/assets/img/image-placeholder.jpg" alt="image">
                </div>
                <div v-if="item.link" class="lb-mb-15">
                  <div class="badge-ml-wrapper">
                  <span class="badge-ml bg-grey color-dark-2 text-sm badge-icon">
                    <span class="icon"><img src="@/assets/img/icons/icon-link.svg" alt="icon"></span>
                    <span v-html="item.link"></span>
                  </span>
                  </div>
                </div>
                <div class="card-title lb-mb-8 h4"><span class="max-l-a">{{item.title}}</span></div>
                <div class="card-text text-md color-grey text-with-editor">
                  <div v-html="item.body"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="thumbnail-add" @click="openNewProject">
              <div class="thumbnail-add-inner">
                <div class="icon"><img src="@/assets/img/icons/icon-add-grey.svg" alt="icon"></div>
                <div class="title text-md text-media">Add new project</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="loader-wrapper">
      <div class="loader-circle">
        <img src="@/assets/img/loader-icon.png" alt="loader">
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="showModal = false"
    >
      <div class="h2 color-dark lb-mb-40">Add new project</div>
      <div v-if="edit_project">
        <div
          class="image-upload lb-mb-15 drop-block"
          @dragover.prevent="dragOver"
          @dragleave.prevent="dragLeave"
          @drop.prevent="drop($event)"
          v-if="isImage"
        >
          <div v-if="!imageUpload" class="image-upload-inner">
            <span class="icon"><img src="@/assets/img/icons/icon-picture-mc.svg" alt="icon"></span>
            <div class="text-in text-sm">Drag the image or <span class="btn-link" @click="triggerUpload">upload</span></div>
            <input
              style="display: none"
              @change="changeFile($event)"
              ref="fileInput"
              type="file"
              accept="image/*"
              name="imgUpload123"
            >
          </div>
          <img v-if="imageUpload" :src="imageUpload" class="up-img" alt="image">
        </div>
        <div class="input-pl-wrapper icon lb-mb-15">
          <span class="icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.9 12c0-1.7 1.4-3.1 3.1-3.1h4V7H7a5 5 0 000 10h4v-1.9H7A3.1 3.1 0 013.9 12zM8 13h8v-2H8v2zm9-6h-4v1.9h4a3.1 3.1 0 010 6.2h-4V17h4a5 5 0 000-10z" fill="#AAA"/></svg></span>
          <input
            type="text"
            class="input-pl sm"
            placeholder="Link in your project"
            @blur="$v.edit_project.link.$touch()"
            v-model="edit_project.link"
          >
        </div>
        <div class="input-pl-wrapper lb-mb-15">
          <input
            type="text"
            class="input-pl xl"
            placeholder="Name of your project"
            @blur="$v.edit_project.title.$touch()"
            v-model="edit_project.title"
          >
        </div>
        <div class="editor-container">
          <div ref="editor" class="editor-wrapper">
            <editor-content :editor="editor" />
            <div class="editor-overflow"></div>
            <div ref="settings" class="editor-settings">
              <input
                type="text"
                v-model="link_input"
                placeholder="url"
              >
              <div class="editor-settings-wrapper">
                <button class="btn editor-settings-btn" @click="closeSettigns()">
                  <span class="icon"><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 12.7c0 .7.6 1.3 1.3 1.3h5.4c.7 0 1.3-.6 1.3-1.3v-8H4v8zM5.3 6h5.4v6.7H5.3V6zm5-3.3L9.7 2H6.3l-.6.7H3.3V4h9.4V2.7h-2.4z" class="fill-grey"/></svg></span>
                </button>
                <button class="btn editor-settings-btn" @click="setLink(classLink)">
                  <span class="icon"><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.3 2h-8C2.6 2 2 2.6 2 3.3v9.4c0 .7.6 1.3 1.3 1.3h9.4c.7 0 1.3-.6 1.3-1.3v-8L11.3 2zm1.4 10.7H3.3V3.3h7.5l1.9 2v7.4zM8 8a2 2 0 100 4 2 2 0 000-4zM4 4h6v2.7H4V4z" class="fill-grey"/></svg></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-editor lb-mb-40">
          <button
            class="btn nav-editor-btn"
            v-if="!isImage"
            @click="isImage = true"
          >
            Add image
          </button>
          <button
            class="btn nav-editor-btn"
            @click="openSettings('btn-outlined')"
          >
            Add button
          </button>
          <button
            class="btn nav-editor-btn"
            @click="openSettings('link')"
          >
            Add link
          </button>
        </div>
        <div class="btn-wrapper">
          <button
            class="btn btn-primary btn-loader"
            @click="saveProject"
            :class="{'disable': $v.$invalid, 'loader': loading_save}"
          >
            Save
          </button>
          <button
            class="btn btn-secondary"
            @click="showModal = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </pop-up>
  </div>
</template>
<script>
import PopUp from '@/components/PopUp'
import { Editor, EditorContent } from '@tiptap/vue-2'
import { defaultExtensions } from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import { required } from 'vuelidate/lib/validators'
export default {
  data () {
    return {
      isImage: false,
      imageUpload: null,
      edit_project: '',
      model_project: {
        title: '',
        image: '',
        link: '',
        body: ''
      },
      editor: null,
      showModal: false,
      classLink: '',
      link_input: '',
      loading_proj: false,
      loading_save: false
    }
  },
  computed: {
    projects () {
      return this.$store.getters.projects
    }
  },
  methods: {
    openNewProject: function () {
      this.showModal = true
      this.edit_project = JSON.parse(JSON.stringify(this.model_project))
    },
    dragOver: function (e) {
      e.target.classList.add('is-hover')
    },
    dragLeave: function (e) {
      e.target.classList.remove('is-hover')
    },
    drop: function (e) {
      const file = e.dataTransfer.files[0]
      const formData = new FormData()
      formData.set('image', file)
      this.edit_project.image = formData
      const reader = new FileReader()
      reader.onload = () => {
        this.imageUpload = reader.result
      }
      reader.readAsDataURL(file)
    },
    triggerUpload: function () {
      this.$refs.fileInput.click()
    },
    changeFile: function (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      formData.set('image', file)
      this.edit_project.image = formData
      const reader = new FileReader()
      reader.onload = () => {
        this.imageUpload = reader.result
      }
      reader.readAsDataURL(file)
    },
    setLink: function (e) {
      this.editor.commands.updateAttributes('a', { rel: 'link-sdf' })
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: this.link_input, target: e })
        .run()
      this.link_input = ''
      this.$refs.editor.classList.remove('is-settings')
    },
    closeSettigns () {
      this.link_input = ''
      this.$refs.editor.classList.remove('is-settings')
    },
    getCaretCoordinates () {
      let x = 0
      let y = 0
      const isSupported = typeof window.getSelection !== 'undefined'
      if (isSupported) {
        const selection = window.getSelection()
        if (selection.rangeCount !== 0) {
          if (selection.anchorNode.nodeName !== '#text') {
            y = selection.anchorNode.getBoundingClientRect().top + 10
            x = selection.anchorNode.getBoundingClientRect().left + 10
          } else {
            const range = selection.getRangeAt(0).cloneRange()
            range.collapse(true)
            const rect = range.getClientRects()[0]
            if (rect) {
              x = rect.left + 4
              y = rect.top + 4
            }
          }
        }
      }
      return { x, y }
    },
    openSettings (e) {
      this.classLink = e
      const $c = this.$refs.settings
      const $e = this.$refs.editor
      const eY = $e.getBoundingClientRect().y
      const eX = $e.getBoundingClientRect().x
      const eH = $e.getBoundingClientRect().height
      const eW = $e.getBoundingClientRect().width
      const { x, y } = this.getCaretCoordinates()
      if (x > eX && y > eY && (eX + eW) > x && (eY + eH) > y) {
        $c.style.left = (x - 150) + 'px'
        $c.style.top = (y - 50) + 'px'
      } else {
        $c.style.left = (eX - 150) + 'px'
        $c.style.top = (eY - 50) + 'px'
      }
      this.$refs.editor.classList.add('is-settings')
    },
    checkURL: function (url) {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null)
    },
    saveProject: function () {
      const d = {
        title: this.edit_project.title,
        link: this.edit_project.link,
        body: this.editor.getHTML(),
        image: this.edit_project.image
      }
      this.loading_save = true
      this.$store.dispatch('sendProjects', d)
        .finally(() => {
          this.loading_save = false
          this.showModal = false
          this.edit_project = ''
          this.fetchProjects()
        })
    },
    fetchProjects: function () {
      if (this.projects === '') {
        this.loading_proj = true
      }
      this.$store.dispatch('fetchProjects')
        .finally(() => {
          this.loading_proj = false
        })
    }
  },
  mounted () {
    this.fetchProjects()
    this.editor = new Editor({
      extensions: [
        ...defaultExtensions(),
        Placeholder,
        Link.configure({
          HTMLAttributes: {
            class: 'link'
          }
        })
      ],
      content: `
      `
    })
    this.editor.getAttributes = function (e) {
      console.log(e)
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  validations: {
    edit_project: {
      title: {
        required
      },
      link: {
        required
      }
    }
  },
  components: {
    PopUp,
    EditorContent
  }
}
</script>

<style lang="scss">
.editor-container{
  .editor{
    &-overflow{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 26;
      display: none;
    }
    &-settings{
      width: 100%;
      max-width: 300px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
      padding-right: 50px;
      position: fixed;
      top: 30px;
      left: 30px;
      z-index: 28;
      display: none;
      input{
        width: 100%;
        height: 32px;
        padding: 0 12px 0 12px;
        background-color: #fff;
        border-radius: 4px;
        outline: none;
        font-size: 14px;
        color: #161616;
        &::placeholder{
          color: #AAAAAA;
        }
      }
      &-wrapper{
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
        line-height: 0;
        .btn{
          margin-right: 8px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      &-btn{
        display: inline-block;
        width: 16px;
        line-height: 0;
        .fill-grey{
          fill: #767676;
          transition: all .24s ease-out;
        }
        .icon{
          display: inline-block;
          line-height: 0;
        }
      }
    }
    &-wrapper{
      &.is-settings{
        .editor-overflow,
        .editor-settings{
          display: block;
        }
      }
    }
  }
  .editor-wrapper{
    display: block;
    margin-bottom: 16px;
    .ProseMirror {
      min-height: 50px;
      outline: none;
      color: #161616;
      border-radius: 6px;
      font-size: 14px;
      > * + * {
        margin-top: 8px;
      }
      a:not(.btn-outlined){
        color: #0F62FE;
        text-decoration: none;
        font-weight: 500;
      }
      a[target='btn-outlined'] {
        padding: 6px 12px;
        border-radius: 6px;
        background-color: rgba(1, 1, 1, 0);
        border: 1px solid #E6E6E6;
        color: #5E4BF1;
        transition: all 0.34s ease-out;
        font-weight: 500;
        margin-right: 8px;
        display: inline;
      }
    }
    .ProseMirror p.is-editor-empty:first-child::before {
      content: 'Description';
      float: left;
      color: #AAAAAA;
      pointer-events: none;
      height: 0;
    }
  }
}
.editor-settings-btn:hover{
  .fill-grey{
    fill: #5E4BF1;
  }
}
.image-upload{
  position: relative;
  padding-bottom: 36%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  max-width: 320px;
  overflow: hidden;
  &-inner{
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    .icon{
      display: inline-block;
      width: 24px;
      line-height: 0;
      img{
        max-width: 100%;
      }
    }
  }
  .up-img{
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.drop-block{
  transition: all .34s ease-out;
  &.is-hover{
      background-color: rgba(0, 0, 0, 0.12);
  }
}
.nav-editor{
  display: flex;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  &-btn{
    padding: 6px 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    color: #5E4BF1;
    font-size: 14px;
    font-weight: 500;
    transition: all .24s ease-out;
    &:last-child{
      border-right: none;
    }
  }
}
.nav-editor-btn:hover{
  opacity: 0.8;
}
</style>
